import * as React from "react";

import { isVariantEnabled } from "components/utility/experiment";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Qualtrics } from "@shared-ui/customer-qualtrics";

interface QualtricsProps {
  pageName: string;
  context: ExtendedContextStore;
}

export const QualtricsScript = ({ context, pageName }: QualtricsProps) => {
  if (pageName === "Homepage" && isVariantEnabled(context, "Qualtrics_Homepage_2021Q4")) {
    return <Qualtrics />;
  }

  if (!pageName || !isVariantEnabled(context, "Qualtrics_Launchpage_2021Q4_v2")) {
    return null;
  }

  return <Qualtrics />;
};
