import * as React from "react";
import { inject, observer } from "mobx-react";
import { UitkToast } from "uitk-react-toast";
import { UIPageStore } from "stores/UIPageStore";
import { UitkIcon } from "uitk-react-icons";
import { UitkSpacing } from "uitk-react-spacing";

export interface NotificationProps {
  uiPage?: UIPageStore;
}

// Listens to the UIPageStore and display notifications
@inject("uiPage")
@observer
export class Notification extends React.Component<NotificationProps> {
  public render() {
    const { uiPage } = this.props;
    if (!uiPage || !uiPage.notification) {
      return null;
    }

    return (
      <UitkToast show={uiPage.notification.isVisible}>
        <UitkSpacing margin={{ inlineend: "three" }}>
          <span>
            <UitkIcon name={uiPage.notification.icon} />
          </span>
        </UitkSpacing>
        {uiPage.notification.message}
      </UitkToast>
    );
  }
}
