/* istanbul ignore file */
import * as React from "react";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";

export const EventWrapper: React.FC = ({ children }) => {
  const track = useEgClickstream();

  React.useEffect(() => {
    track({
      event: {
        event_type: "Interaction",
        event_name: "clickstream_sdk.presented",
        event_version: "1.0.0",
      },
    });
  }, []);

  return <>{children}</>;
};

export default EventWrapper;
