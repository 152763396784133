import * as React from "react";
import { buildStateProvider } from "@shared-ui/provided-state-context";
import { VirtualAgentControlState } from "@shared-ui/customer-virtual-agent-control";
import { CustomerNotificationControlState } from "@shared-ui/customer-notifications";

const StateProvider = buildStateProvider(new VirtualAgentControlState(), new CustomerNotificationControlState());

export const ProvidedStateWrapper: React.FC = ({ children }) => {
  return <StateProvider>{children}</StateProvider>;
};
