import * as React from "react";
import { isVariantEnabled } from "components/utility/experiment";
import { RedirectBannerProps } from "./typings";

const RedirectBannerWrapper = (props: RedirectBannerProps) => {
  const { context } = props;

  const [redirectBanner, setRedirectBanner] = React.useState<React.ReactElement | null>(null);
  const redirectBannerOn = isVariantEnabled(context, "posa_switcher_sf_rollout");

  React.useEffect(() => {
    if (!redirectBannerOn) {
      return;
    }

    const getRedirectBanner = async () => {
      const { RedirectBanner } = await import(/* webpackChunkName: "RedirectBanner" */ "redirect-banner-react");

      setRedirectBanner(<RedirectBanner siteid={context.site.id} langid={context.langId} />);
    };

    getRedirectBanner();
  }, []);

  return redirectBanner;
};

export default RedirectBannerWrapper;
