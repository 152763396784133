import * as React from "react";
import { AuthenticationState } from "bernie-context";
import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";

export const MATCHING_PAGE_IDS = ["Homepage,U,10"];

interface DelayFabEntryExperimentProps {
  authState: AuthenticationState;
  pageId: string;
}

export const DelayFabEntryExperiment: React.FC<React.PropsWithChildren<DelayFabEntryExperimentProps>> = ({
  authState,
  children,
  pageId,
}) => {
  if (authState === AuthenticationState.AUTHENTICATED && MATCHING_PAGE_IDS.includes(pageId)) {
    return (
      <span data-testid="delay-fab-entry-experiment">
        <Experiment name="Delay_Entry_into_FAB_on_Homepage_Authenticated_Web">
          <ExperimentControl>{children}</ExperimentControl>
          <ExperimentVariant bucket={1} />
        </Experiment>
      </span>
    );
  }
  return <>{children}</>;
};
