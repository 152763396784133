import { useApolloClient, from } from "@apollo/client";
import * as React from "react";
import { withStores } from "stores";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { LcpImageCacheLink } from "./LcpImageCache/LcpImageCacheLink";
import MojoTokenReplacerLink from "./Mojo/MojoTokenReplacerLink";
import { useLogger } from "@shared-ui/logger-context";

export const ApolloServerLinkInjector: React.FC<{
  context?: ExtendedContextStore;
}> = withStores("context")(({ context }) => {
  const client = useApolloClient();
  const logger = useLogger();

  React.useMemo(() => {
    if (typeof window === "undefined") {
      client.setLink(from([LcpImageCacheLink(context), MojoTokenReplacerLink(context, logger), client.link]));
    }
  }, []);
  return null;
});
