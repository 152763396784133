import * as React from "react";

type StickyItems = Record<
  string,
  {
    /**
     * ref of the actual sticky element
     */
    ref: React.RefObject<HTMLElement>;
    /**
     * ref of the wrapper element, useful to get the real height and top properties without attaching a resize handler.
     */
    parentRef: React.RefObject<HTMLElement>;
    offsetHeight: number;
    offsetTop: number;
    trigger: "top" | "bottom";

    isSticky?: boolean;
    padded?: boolean;
  }
>;

type StickyStyle = React.CSSProperties;
type StickyStyles = Record<string, StickyStyle>;

const topStickyStyles = {
  background: "white",
  padding: "0.75rem",
  transition: "padding 0.2s ease-in-out",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
};

const bottomStickyStyles = {
  background: "white",
  padding: "0.75rem",
  top: "-100px",
  transform: "translateY(99px)",
  transition: "padding 0.2s ease-in-out, transform 0.2s ease-in-out",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
};

interface IContext {
  register: (
    id: string,
    ref: React.RefObject<HTMLElement>,
    parentRef: React.RefObject<HTMLElement>,
    trigger: "top" | "bottom",
    padded?: boolean
  ) => void;
  getStyles: (id: string) => { style?: StickyStyle };
}

const Context = React.createContext<IContext>({
  // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
  register: () => {},
  getStyles: () => {
    return {};
  },
});
const useStackedSticky = () => React.useContext(Context);

/**
 * Responsible for listening to the scroll event and updating the sticky elements
 * styling depending on their positions and the scroll state.
 */
export const StackedStickyProvider: React.FC = ({ children }) => {
  // Don't use state, we don't want to cause re-renders when registering an item.
  const items = React.useRef<StickyItems>({});
  const [styles, setStyles] = React.useState<StickyStyles>({});

  React.useEffect(() => {
    const scrollHandler = () => {
      let dirty = false;
      const updatedStyles: StickyStyles = {};

      // loop over all the registered elements and compute if they need to be sticky or not
      Object.keys(items.current).reduce((totalHeight, id) => {
        const item = items.current[id];
        const initialState = item.isSticky;

        if (!item.parentRef.current) {
          return totalHeight;
        }

        // formula is scrollY + totalHeigth (position where things should start to be sticky)
        // >=
        // scrollY + container's top (don't use parentRef.current.offsetTop, doesn't work with relatively positioned elements)
        const { top, bottom } = item.parentRef.current.getBoundingClientRect();
        const maxHeight = item.trigger === "top" ? top : bottom;

        if (totalHeight >= maxHeight) {
          updatedStyles[id] = {
            position: "fixed",
            top: totalHeight,
            width: item.parentRef.current.offsetWidth,
            zIndex: 400,
          };
          item.isSticky = true;
        } else {
          updatedStyles[id] = {
            position: "static",
            top: "unset",
          };
          item.isSticky = false;
        }

        // Verify if the position change, to avoid trigerring a useless render
        if (initialState !== item.isSticky) {
          dirty = true;
        }

        return totalHeight + item.parentRef.current.offsetHeight;
      }, 0);

      // apply a state update only if something changed.
      if (dirty) {
        setStyles(updatedStyles);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const register = (
    id: string,
    ref: React.RefObject<HTMLElement>,
    parentRef: React.RefObject<HTMLElement>,
    trigger: "top" | "bottom",
    padded?: boolean
  ) => {
    const { offsetHeight, offsetTop } = ref.current || {};
    if (offsetHeight === undefined || offsetTop === undefined) {
      return;
    }

    items.current[id] = { ref, parentRef, offsetHeight, offsetTop, padded, isSticky: false, trigger };
  };

  // Safe getter
  const getStyles = (id: string): { style?: StickyStyle } => {
    if (id && styles[id] && items.current[id]) {
      const stylesToMerge = items.current[id].isSticky
        ? items.current[id].trigger === "bottom"
          ? bottomStickyStyles
          : topStickyStyles
        : {};

      const style = {
        ...styles[id],
        ...stylesToMerge,
      };

      if (!items.current[id].padded) {
        style.padding = "0";
      }

      return {
        style,
      };
    }

    return {};
  };

  return <Context.Provider value={{ register, getStyles }}>{children}</Context.Provider>;
};

const StickyElementContext = React.createContext<{ isSticky: boolean }>({ isSticky: false });
export const useStickyElement = () => React.useContext(StickyElementContext);

export const StackedSticky: React.FC<{ trigger?: "bottom" | "top"; padded?: boolean }> = ({
  trigger = "top",
  padded,
  children,
}) => {
  const [id, setId] = React.useState("");

  const parentRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const { register, getStyles } = useStackedSticky();

  React.useEffect(() => {
    const randomId = Math.random().toString(36).substr(2, 5);

    register(randomId, ref, parentRef, trigger, padded);
    setId(randomId);
  }, []);

  const { style } = getStyles(id);

  return (
    <StickyElementContext.Provider value={{ isSticky: style?.position === "fixed" }}>
      <div ref={parentRef} style={{ height: "auto", width: "100%" }} data-testid="stacked-sticky-element">
        <div ref={ref} style={style}>
          {children}
        </div>
      </div>
    </StickyElementContext.Provider>
  );
};

/**
 * Usage
 *
 * somewhere up near the root of the tree, make sure to have the <StackedStickyProvider />
 *
 * in your component(s):
 *
 * const MyComponent: React.FC = () => (
 *  <StackedSticky>
 *   <div>Very Important Pickle</div>
 *  </StackedSticky>
 * )
 */
